import { useState, useRef } from 'react';
import { cn } from '@cardo/lib';
import { useClickAway } from 'react-use';
import { IoCloseSharp } from 'react-icons/io5';

const copy =
  'Some of the card links and other products that appear on this website are from companies which Cardonomics will earn an affiliate commission or referral bonus. Cardonomics is part of an affiliate sales network and receives compensation for sending traffic to partner sites. This compensation may impact how and where products appear on this site (including, for example, the order in which they appear). This site does not include all credit card companies or all available credit card offers.';

export default function AdvertiserDisclosure({
  className,
  modalClassName,
}: {
  className?: string;
  modalClassName?: string;
}) {
  const ref = useRef(null);
  const [open, setOpen] = useState<boolean>(false);

  useClickAway(ref, () => {
    setOpen(false);
  });

  return (
    <div className={cn('text-sm', className)} ref={ref}>
      <div className="cursor-pointer " onClick={() => setOpen((prev) => !prev)}>
        Advertiser Disclosure
      </div>
      {open && (
        <>
          <div
            className={cn(
              'absolute hidden md:flex flex-col top-0 md:top-full border z-50 right-0 w-96 p-5 bg-white rounded-lg shadow-md mt-4',
              modalClassName,
            )}
          >
            <p>{copy}</p>
          </div>
          <div
            className={cn(
              'md:hidden fixed top-2 left-2 right-2 border z-[1000] p-5 bg-white rounded-lg shadow-md flex flex-col gap-4 overflow-y-auto',
              modalClassName,
            )}
          >
            <div className="flex justify-between">
              <h4>Advertiser Disclosure</h4>
              <button type="button" onClick={() => setOpen(false)}>
                <IoCloseSharp className="text-3xl" />
              </button>
            </div>
            <p className="text-base font-normal">{copy}</p>
          </div>
        </>
      )}
    </div>
  );
}
